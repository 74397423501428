<template>
  <!-- <div id="edithistoryLog" v-if="false"> -->
  <div
    class="edit-history-container"
    @click="editHistoryEvent"
    v-if="dialogElementShow.xppEditHistoryLogState"
  >
    <div class="history-header">
      <h2>版本历史记录</h2>
      <h3>
        Restored version will change the current canvas. All other versions will
        be available to restore
      </h3>
    </div>
    <div class="history-container xpp-scrollbar">
      <ul>
        <li
          class="history-item display-flex"
          v-for="(item, index) in editHistoryLogList"
          :key="item._id"
        >
          <div class="history-center ql-editor">
            <div class="history-object-view" v-html="item.data.content"></div>
          </div>
          <div class="history-info display-flex">
            <div>
              <div class="display-flex flex-start">
                <div
                  class="history-user-photo xpp-user-photo"
                  :style="item.data.updataInfo.headImg | styleUserPhoto"
                ></div>
                <div class="history-user-name">
                  <span>修改者：</span
                  ><span class="text-overflow">{{
                    item.data.updataInfo.nickName
                  }}</span>
                </div>
              </div>
              <span class="history-user-uptime">{{
                item.data.updataInfo.time | dateToLocale
              }}</span>
            </div>
            <div
              class="history-state-btn"
              :class="{ 'history-atcive': item.active }"
              :data-index="index"
            ></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "EditHistoryLog",
  computed: {
    ...mapGetters(["dialogElementShow", "editHistoryLogList"])
  },
  methods: {
    editHistoryEvent({ target }) {
      let classname = target.classList;
      if (
        classname.contains("edit-history-container") ||
        classname.contains("history-container")
      ) {
        return this.closeView();
      }
      if (
        classname.contains("history-state-btn") &&
        !classname.contains("history-atcive")
      ) {
        let { _id, data } = this.editHistoryLogList[target.dataset.index];
        this.$RecoveryEditHistoryInfo(data);
        this.$WsSendMsg({
          action: "EDITRESTORE",
          data
        });
        this.closeView();
      }
    },
    closeView() {
      return this.$DiaLoginFoUpDate({
        key: "xppEditHistoryLogState",
        value: false
      });
    },
    ...mapMutations(["$DiaLoginFoUpDate", "$RecoveryEditHistoryInfo"]),
    ...mapActions(["$WsSendMsg"])
  }
};
</script>

<style lang="scss" scoped>
.edit-history-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(235, 238, 242, 0.9);
  z-index: 2001;
}
.history-header {
  padding: 32px 40px;
  vertical-align: middle;
  color: #9197a3;
  pointer-events: none;
  h2 {
    font-size: 26px;
    margin-bottom: 12px;
    font-weight: 400;
  }
  h3 {
    font-size: 14px;
    font-weight: normal;
  }
}
.history-container {
  align-items: flex-start;
  height: calc(100vh - 125px);
  overflow: auto;
  display: flex;
  justify-content: center;
  & > ul {
    flex: none;
    margin-bottom: 200px;
  }
  .history-item {
    width: 750px;
    height: 160px;
    border: 1px solid #dbdee6;
    padding: 4px 4px 5px;
    background-color: #fff;
    margin-bottom: 20px;
  }
  .history-center {
    flex: none;
    width: 240px;
    height: 150px;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 4px;
    transition: box-shadow 0.3s;
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0 3px #f99000;
    }
  }
  .history-object-view {
    pointer-events: none;
  }
  .history-info {
    padding: 45px 16px 42px 26px;
    flex: auto;
  }
  .history-user-photo {
    width: 26px;
    height: 26px;
    border: 1px solid #fff;
    margin-right: 10px;
  }
  .history-user-name {
    font-size: 14px;
    font-weight: 700;
    span {
      max-width: 140px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .history-user-uptime {
    display: block;
    color: #9197a3;
    font-size: 13px;
    margin-top: 11px;
    text-align: right;
  }
  .history-state-btn {
    padding-left: 15px;
    padding-right: 15px;
    line-height: 38px;
    font-size: 12px;
    text-align: center;
    border: 1px solid #f5a623;
    background-color: #f5a623;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    &::before {
      content: "恢复";
    }
    &.history-atcive {
      border-color: #dbdee6;
      background-color: #ebeef2;
      color: #9197a3;
      cursor: no-drop;
      &::before {
        content: "当前版本";
      }
    }
  }
}
</style>
